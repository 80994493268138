import {Menu, Transition} from '@headlessui/react';
import UserInitials from '../../common/UserInitials';
import {Fragment} from 'react';
import {
  ArrowRightOnRectangleIcon,
  BuildingOfficeIcon,
  CogIcon,
  UserCircleIcon,
  UserGroupIcon,
  UserPlusIcon,
} from '@heroicons/react/24/outline';
import {classNames} from '../../utils/helper';
import {NavLink} from 'react-router-dom';
import {dispatch} from 'redux/store';
import {logoutUser} from 'redux/reducers/auth';

const AccountDropdowns = [
  {
    title: 'View Profile',
    icon: UserCircleIcon,
    active: false,
    href: '/profile',
  },
  {title: 'Organization Profile', icon: BuildingOfficeIcon, href: '/'},
  {title: 'Team', icon: UserGroupIcon, href: '/'},
  {title: 'Invite Colleagues', icon: UserPlusIcon, href: '/'},
  {title: 'Project settings', icon: CogIcon, href: '/'},
];

const AccountDropdownMenu = () => {
  const onLogoutClick = async () => {
    try {
      await dispatch(logoutUser()).unwrap();
      // showToast('success', `Fetched ${user.name}`)
    } catch (err) {
      // showToast('error', `Fetch failed: ${err.message}`)
    }
  };

  return (
    <Menu as="div" className="relative ">
      <div>
        <Menu.Button
          className={
            'flex justify-center items-center max-w-xs items-center rounded-full text-sm focus:outline-none' +
            ' h-9 w-9  ' +
            ' rounded-full hover:bg-opacity-20 hover:bg-primary-900 focus:bg-primary-900  focus:bg-opacity-20 '
          }
        >
          <span className="sr-only">Open user menu</span>
          <UserInitials initials="BM" />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className="absolute right-0 z-10 mt-2 w-60 origin-top-right 
          rounded-sm bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
        >
          <div className="px-4 py-3 gap-2 flex w-full border-b border-gray-300 items-center">
            <UserInitials initials="BM" size="md" />
            <div>
              <h6 className="text-gray-700 mb-0 text-sm font-semibold">
                Bello Muktar
              </h6>
              <p className="text-sm font-normal text-gray-700 ">
                muktar.bello@amplon.io
              </p>
            </div>
          </div>
          {AccountDropdowns.map((item, key) => (
            <NavLink
              key={key}
              to={item.href}
              className={classNames(
                item?.active ? 'bg-gray-100' : '',
                'flex hover:bg-gray-100 px-5 h-[42px] items-center font-medium text-sm text-gray-700'
              )}
            >
              <item.icon className="w-6 h-6 text-gray-700 mr-2" />
              <span> {item.title}</span>
            </NavLink>
          ))}
          <a
            onClick={() => onLogoutClick()}
            className={classNames(
              'cursor-pointer flex hover:bg-gray-100 px-5 h-[42px] \
               items-center font-medium text-sm text-gray-700 border-t border-gray-300'
            )}
          >
            <ArrowRightOnRectangleIcon className="w-6 h-6 text-gray-700 mr-2" />
            <span> Log out</span>
          </a>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default AccountDropdownMenu;

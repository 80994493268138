import {GROUP_ITEMS} from '../option-data';
import {createSlice} from '@reduxjs/toolkit';
import {TOptionInitState} from 'redux/types';

const initialState: TOptionInitState = {
  GROUP_ITEMS,
};

const slice = createSlice({
  name: 'option',
  initialState,
  reducers: {},
});

export default slice.reducer;

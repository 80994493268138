import {useSelector} from 'react-redux';
import {Navigate, Outlet} from 'react-router-dom';
import {RootState} from 'redux/rootReducer';

export default function AuthLayout() {
  const {auth} = useSelector((state: RootState) => state);

  if (auth.isAuthenticated) {
    return <Navigate to="/dashboard" />;
  }

  return <Outlet />;
}

import React from 'react';
import {NavLink} from 'react-router-dom';

interface INavItemProps {
  text: string;
  active?: boolean;
  to: string;
  end?: boolean;
  IconElement: React.ElementType;
}

export default function NavItem({to, text, IconElement, end}: INavItemProps) {
  return (
    <li>
      <NavLink
        className={({isActive}) =>
          'group flex p-2 gap-x-3 items-center text-[14px] font-medium ' +
          'text-gray-700 rounded-lg hover:bg-primary-700 hover:bg-opacity-10 hover:text-primary-700 ' +
          (isActive ? 'bg-primary-700 bg-opacity-10' : '')
        }
        end={end}
        to={to}
      >
        <IconElement className=" w-6 h-6 block float-left text-gray-700 group-hover:text-primary-700 " />
        <span className="flex whitespace-nowrap duration-200 origin-left flex-nowrap overflow-hidden  ">
          {text}
        </span>
      </NavLink>
    </li>
  );
}

import axios from 'axios';
import * as Sentry from '@sentry/react';
import {dispatch} from '../redux/store';
import {logoutUser} from '../redux/reducers/auth';
import {memoizedRefreshToken} from './refresh-token';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  withCredentials: true,
});

axiosInstance.interceptors.response.use(
  async response => response,
  async error => {
    const originalRequest = error?.config || {};

    if (error?.response?.status >= 500) {
      Sentry.captureException(error);
    }
    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const result = await memoizedRefreshToken();

      if (result) {
        return axios(originalRequest);
      }
      await dispatch(logoutUser());
    }

    return Promise.reject(error);
  }
);
export default axiosInstance;

import {Logo} from 'ui-library';

const LoadingScreen = () => (
  <>
    <div className="flex justify-center mt-24">
      <img className="w-[800px]" src="/img/Amplon splash screen.jpg" />
    </div>
    <div className="flex justify-center">
      <span className="flex justify-end w-[800px]">
        <Logo type="long" />
      </span>
    </div>
  </>
);

export default LoadingScreen;

export const GROUP_ITEMS = [
  {
    label: 'group',
    value: '3',
    children: [
      {
        label: 'finland',
        value: '3',
        children: [
          {
            label: 'production',
            value: '3',
          },
          {
            label: 'people',
            value: '3',
          },
        ],
      },
    ],
  },
  {
    label: 'uk',
    value: '3',
  },
];

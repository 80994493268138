import {SVGProps} from 'react';
import NavItem from './NavItem';
import {
  ArrowTrendingDownIcon,
  ArrowTrendingUpIcon,
  BuildingOffice2Icon,
  FolderArrowDownIcon,
  HashtagIcon,
  HomeIcon,
  UsersIcon,
  UserGroupIcon,
} from '@heroicons/react/24/outline';
import NavTitle from './NavTitle';

type SideBarItem =
  | {
      type: 'Title';
      title: string;
    }
  | {
      type: 'Link';
      title: string;
      to: string;
      end?: boolean;
      icon: (
        props: SVGProps<SVGSVGElement> & {
          title?: string | undefined;
          titleId?: string | undefined;
        }
      ) => JSX.Element;
    };

const sideBarItems: SideBarItem[] = [
  {
    type: 'Title',
    title: 'Main',
  },
  {
    type: 'Link',
    title: 'Overview',
    icon: HomeIcon,
    to: '/',
    end: true,
  },
  {
    type: 'Link',
    title: 'Organizations',
    icon: BuildingOffice2Icon,
    to: '/organizations',
  },
  {
    type: 'Link',
    title: 'Users',
    icon: UsersIcon,
    to: '/users',
  },
  {
    type: 'Link',
    title: 'Admin Users',
    icon: UserGroupIcon,
    to: '/admin-users',
  },
  {
    type: 'Title',
    title: 'Reports',
  },
  {
    type: 'Link',
    title: 'Login Report',
    icon: ArrowTrendingUpIcon,
    to: '/login-report',
  },
  {
    type: 'Link',
    title: 'False Login Report',
    icon: ArrowTrendingDownIcon,
    to: '/falselogin',
  },
  {
    type: 'Link',
    title: 'Data Export',
    icon: FolderArrowDownIcon,
    to: '/dataexport',
  },
  {
    type: 'Link',
    title: 'User Notifications',
    icon: HashtagIcon,
    to: '/user-notifications',
  },
];

const Sidebar = () => (
  <div className="h-screen pt-[3px] bg-white border-r relative w-full px-4">
    <div className="h-full">
      <div className="h-14 mt-3 flex items-center">
        <div className="inline-flex items-center">
          <h1 className="text-[24px] duration-300 font-semibold text-gray-600">
            Amplon.io
          </h1>
          <img
            className="w-auto h-8 ml-1"
            src="/img/amplon-logo.png"
            alt="Amplon"
          />
        </div>
      </div>
      <nav className="mt-3">
        <ul className="space-y-2">
          {sideBarItems.map((item, index) =>
            item.type === 'Link' ? (
              <NavItem
                to={item.to}
                key={index}
                text={item.title}
                IconElement={item.icon}
                end={item.end}
              />
            ) : (
              <NavTitle text={item.title} />
            )
          )}
        </ul>
      </nav>
    </div>
  </div>
);

export default Sidebar;

import {LazyExoticComponent} from 'react';
import AuthenticatedLayout from '../layouts/AuthenticatedLayout';
import AuthLayout from '../layouts/AuthLayout';
import {Suspense, lazy} from 'react';
import {useRoutes} from 'react-router-dom';
import AuthGuard from '../_guards/AuthGuard';

const Loadable =
  (Component: LazyExoticComponent<() => JSX.Element>) =>
  (props: {[key: string]: unknown}) =>
    (
      <Suspense fallback="Loading">
        <Component {...props} />
      </Suspense>
    );

export default function Router() {
  const element = useRoutes([
    {
      path: 'auth',
      element: <AuthLayout />,
      children: [
        {path: 'login', element: <Login />},
        {path: 'forgot-password', element: <ForgotPassword />},
        {path: 'reset-password', element: <ResetPassword />},
        {path: 'reset-password/:token', element: <ResetPassword />},
      ],
    },
    {
      path: '',
      element: (
        <AuthGuard>
          <AuthenticatedLayout />
        </AuthGuard>
      ),
      children: [{path: '', element: <Dashboard />}],
    },
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <AuthenticatedLayout />
        </AuthGuard>
      ),
      children: [{path: '', element: <Dashboard />}],
    },
    {
      path: 'users',
      element: (
        <AuthGuard>
          <AuthenticatedLayout />
        </AuthGuard>
      ),
      children: [{path: '', element: <UsersList />}],
    },
    {
      path: 'admin-users',
      element: (
        <AuthGuard>
          <AuthenticatedLayout />
        </AuthGuard>
      ),
      children: [{path: '', element: <AdminUsersList />}],
    },
    {
      path: 'organizations',
      element: (
        <AuthGuard>
          <AuthenticatedLayout />
        </AuthGuard>
      ),
      children: [
        {path: '', element: <Organizations />},
        {
          path: ':organizationId/organization-units',
          element: <OrganizationUnits />,
        },
        {
          path: ':organizationId/users',
          element: <OrganizationUsers />,
        },
        {
          path: ':organizationId/authorizations',
          element: <OrganizationAuthorizations />,
        },
      ],
    },
    {
      path: 'organization_and_roles',
      element: (
        <AuthGuard>
          <AuthenticatedLayout />
        </AuthGuard>
      ),
      children: [
        {path: '', element: <OrganizationAndRoles />},
        {path: 'create', element: <CreateOrganizationAndRoles />},
      ],
    },
    {
      path: 'login-report',
      element: (
        <AuthGuard>
          <AuthenticatedLayout />
        </AuthGuard>
      ),
      children: [{path: '', element: <LoginReport />}],
    },
    {
      path: 'falselogin',
      element: (
        <AuthGuard>
          <AuthenticatedLayout />
        </AuthGuard>
      ),
      children: [{path: '', element: <FalseLogin />}],
    },
    {
      path: 'dataexport',
      element: (
        <AuthGuard>
          <AuthenticatedLayout />
        </AuthGuard>
      ),
      children: [{path: '', element: <DataExport />}],
    },
    {
      path: 'user-notifications',
      element: (
        <AuthGuard>
          <AuthenticatedLayout />
        </AuthGuard>
      ),
      children: [
        {path: '', element: <UserNotifications />},
        {path: 'create', element: <CreateUserNotifications />},
      ],
    },
    {
      path: 'profile',
      element: (
        <AuthGuard>
          <AuthenticatedLayout />
        </AuthGuard>
      ),
      children: [{path: '', element: <UpdateProfile />}],
    },
    {
      path: '',
      element: <AuthLayout />,
      children: [{path: '*', element: <NotFound />}],
    },
  ]);

  return element;
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const ForgotPassword = Loadable(
  lazy(() => import('../pages/auth/ForgotPassword'))
);
const ResetPassword = Loadable(
  lazy(() => import('../pages/auth/ResetPassword'))
);

const Dashboard = Loadable(lazy(() => import('../pages/dashboard')));

//user
const UsersList = Loadable(lazy(() => import('../pages/users')));

//admin-users
const AdminUsersList = Loadable(lazy(() => import('../pages/admin-users')));

//Organizations
const Organizations = Loadable(lazy(() => import('../pages/organizations')));

//Organizations Unit
const OrganizationUnits = Loadable(
  lazy(() => import('../pages/organizations/organization-units'))
);
//Organization Users
const OrganizationUsers = Loadable(
  lazy(() => import('../pages/organizations/organization-users'))
);
//Organization Authorizations
const OrganizationAuthorizations = Loadable(
  lazy(() => import('../pages/organizations/authorizations'))
);

//Organization And Roles
const OrganizationAndRoles = Loadable(
  lazy(() => import('../pages/organization-roles'))
);
const CreateOrganizationAndRoles = Loadable(
  lazy(() => import('../pages/organization-roles/create'))
);

//Login Report
const LoginReport = Loadable(lazy(() => import('../pages/login-report')));

//False Login
const FalseLogin = Loadable(lazy(() => import('../pages/false-login')));

//DataExport
const DataExport = Loadable(lazy(() => import('../pages/data-export')));

//DataExport
const UserNotifications = Loadable(
  lazy(() => import('../pages/user-notifications'))
);
const CreateUserNotifications = Loadable(
  lazy(() => import('../pages/user-notifications/create'))
);
//UpdateProfile
const UpdateProfile = Loadable(
  lazy(() => import('../pages/profile/UpdateProfile'))
);

const NotFound = Loadable(lazy(() => import('../pages/404')));

interface INavItemProps {
  text: string;
}

export default function NavTitle({text}: INavItemProps) {
  return (
    <li>
      <span
        className={
          'flex whitespace-nowrap font-semibold text-[13px] text-gray-700' +
          ' mt-7 uppercase duration-200 origin-left flex-nowrap overflow-hidden  '
        }
      >
        {text}
      </span>
    </li>
  );
}

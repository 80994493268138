import {Bars3Icon} from '@heroicons/react/20/solid';
import AccountDropdownMenu from './AccountDropdownMenu';
import {BreadCrumb, Typography} from 'ui-library';
import {ETypographyVarient} from 'ui-library/_enum';
import {useSelector} from 'react-redux';
import {RootState} from '../../redux/rootReducer';

// TODO:
// const solutions = [
//   {
//     name: 'Analytics',
//     description:
//       'Get a better understanding of where your traffic is coming from.',
//     href: '#',
//     icon: ChartBarIcon,
//   },
//   {
//     name: 'Engagement',
//     description: 'Speak directly to your customers in a more meaningful way.',
//     href: '#',
//     icon: CursorArrowRaysIcon,
//   },
//   {
//     name: 'Security',
//     description: "Your customers' data will be safe and secure.",
//     href: '#',
//     icon: ShieldCheckIcon,
//   },
//   {
//     name: 'Integrations',
//     description: "Connect with third-party tools that you're already using.",
//     href: '#',
//     icon: Squares2X2Icon,
//   },
//   {
//     name: 'Automations',
//     description:
//       'Build strategic funnels that will drive your customers to convert',
//     href: '#',
//     icon: ArrowPathIcon,
//   },
// ];
// const callsToAction = [
//   {name: 'Watch Demo', href: '#', icon: PlayIcon},
//   {name: 'Contact Sales', href: '#', icon: PhoneIcon},
// ];
// const resources = [
//   {
//     name: 'Help Center',
//     description:
//       'Get all of your questions answered in our forums or contact support.',
//     href: '#',
//     icon: LifebuoyIcon,
//   },
//   {
//     name: 'Guides',
//     description:
//       'Learn how to maximize our platform to get the most out of it.',
//     href: '#',
//     icon: BookmarkSquareIcon,
//   },
//   {
//     name: 'Events',
//     description:
//       'See what meet-ups and other events we might be planning near you.',
//     href: '#',
//     icon: CalendarIcon,
//   },
//   {
//     name: 'Security',
//     description: 'Understand how we take your privacy seriously.',
//     href: '#',
//     icon: ShieldCheckIcon,
//   },
// ];
// const recentPosts = [
//   {id: 1, name: 'Boost your conversion rate', href: '#'},
//   {
//     id: 2,
//     name: 'How to use search engine optimization to drive traffic to your site',
//     href: '#',
//   },
//   {id: 3, name: 'Improve your customer experience', href: '#'},
// ];

// const user = {
//   name: 'Tom Cook',
//   email: 'tom@example.com',
//
// };

export default function Header({toggleSidebar}: {toggleSidebar: () => void}) {
  const {breadcrumb} = useSelector((state: RootState) => state);
  const navIconClassNames =
    'inline-flex items-center ' +
    'justify-center rounded-full bg-white p-2 text-gray-600 hover:bg-gray-100 ' +
    'hover:text-gray-800 focus:outline-none cursor-pointer';

  return (
    <header
      className="bg-white relative flex flex-0 items-center 
      w-full h-16 px-4 md:px-6 z-49 shadow dark:border-b bg-card print:hidden"
    >
      <nav className="w-full flex justify-between">
        <div className="flex items-center gap-x-3">
          <button
            type="button"
            onClick={() => toggleSidebar()}
            className={`${navIconClassNames}`}
          >
            <span className="sr-only">Open menu</span>
            <Bars3Icon className="h-6 w-6 font-bold" aria-hidden="true" />
          </button>
          <Typography varient={ETypographyVarient.H4}>Super admin</Typography>
        </div>

        <ul className="flex justify-center items-center gap-x-2 md:gap-x-6">
          <li>
            <BreadCrumb items={breadcrumb?.items} />
          </li>
          <AccountDropdownMenu />
        </ul>
      </nav>
    </header>
  );
}

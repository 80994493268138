import LoadingScreen from 'components/LoadingScreen';
import {useEffect} from 'react';
import {ReactNode, useState} from 'react';
import {useSelector} from 'react-redux';
import {Navigate, useLocation} from 'react-router-dom';
import {checkSession} from '../redux/reducers/auth';
import {RootState} from '../redux/rootReducer';
import {dispatch} from '../redux/store';

interface IAuthGuard {
  children: ReactNode;
}

export default function AuthGuard({children}: IAuthGuard) {
  const {isAuthenticated, isAuthenticating} = useSelector(
    (state: RootState) => state.auth
  );

  const {pathname} = useLocation();

  const [requestedLocation, setRequestedLocation] = useState<string | null>(
    null
  );

  useEffect(() => {
    const getSession = async () => {
      try {
        await dispatch(checkSession()).unwrap();
      } catch (err) {
        console.error(err);
      }
    };

    getSession();
  }, []);

  if (isAuthenticating) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }

    return <Navigate to="/auth/login" />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);

    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}

import {createAsyncThunk} from '@reduxjs/toolkit';
import axiosInstance from 'utils/axios';

export type LogInPostData = {
  email: string;
  password: string;
};

export type ResetPasswordPostData = {
  password: string;
  password_confirmation: string;
  token: string | null;
};

export type ForgotPasswordPostData = {
  email: string;
};

export const checkSession = createAsyncThunk<any>(
  'auth/checkSession',
  async () => await axiosInstance.get('session')
);

export const loginUser = createAsyncThunk(
  'auth/login',
  async (data: LogInPostData, {rejectWithValue}) => {
    try {
      return await axiosInstance.post('login', data);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const logoutUser = createAsyncThunk('auth/logout', async () => true);

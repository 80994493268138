import {combineReducers} from 'redux';
// slices
import authReducer from './slices/auth';
import breadcrumb from './slices/breadcrumb';
import option from './slices/option';

const rootReducer = combineReducers({
  auth: authReducer,
  option,
  breadcrumb,
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;

import {createSlice} from '@reduxjs/toolkit';
import {checkSession, loginUser, logoutUser} from 'redux/reducers/auth';
import {TAuthInitState} from 'redux/types';

const initialState: TAuthInitState = {
  isAuthenticated: false,
  isAuthenticating: true,
  loading: false,
  user: null,
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(loginUser.pending, state => {
        state.loading = true;
      })
      .addCase(loginUser.fulfilled, state => {
        state.loading = false;
        state.isAuthenticated = true;
        state.isAuthenticating = false;
      })
      .addCase(loginUser.rejected, state => {
        state.loading = false;
        state.isAuthenticated = false;
        state.isAuthenticating = false;
      })
      .addCase(logoutUser.fulfilled, state => {
        state.loading = false;
        state.isAuthenticated = false;
      })
      .addCase(checkSession.fulfilled, state => {
        state.loading = false;
        state.isAuthenticating = false;
        state.isAuthenticated = true;
      })
      .addCase(checkSession.rejected, state => {
        state.loading = false;
        state.isAuthenticating = false;
        state.isAuthenticated = false;
      });
  },
});

export default slice.reducer;

export const {actions} = slice;

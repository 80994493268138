import {SortingState} from '@tanstack/react-table';

export const classNames = (...classes: any[]) =>
  classes.filter(Boolean).join(' ');

export const getQueryString = ({
  sorting,
  pagination,
}: {
  sorting: SortingState;
  pagination: {
    pageIndex: number;
    pageSize: number;
  };
  others?: Record<string, unknown>;
}) => {
  let queryString = '';

  if (sorting.length > 0) {
    queryString = `sorts[${sorting[0].id}]=${sorting[0].desc ? 'desc' : 'asc'}`;
  }
  queryString += `&page=${pagination.pageIndex + 1}`;
  queryString += `&limit=${pagination.pageSize}`;

  return queryString;
};
